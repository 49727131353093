<template>
  <v-text-field
    v-model="searchString"
    :placeholder="label"
    hide-details
    clearable
    solo
    prepend-inner-icon="mdi-magnify"
    class="mx-7 my-3 v-text-field__search" />
</template>
<script>
export default {
  name: 'ProjectsTableStructureSearching',
  props: {
    label: {
      type: String,
      default: 'Search by Project Name/Number',
    },
    search: {
      type: String,
      default: '',
    },
  },
  computed: {
    searchString: {
      get() {
        return this.search;
      },
      set(val) {
        this.$emit('update:search', val);
      },
    },
  },
};
</script>
<style scoped lang="scss">
  ::v-deep input {
    font-size: 14px !important;
  }
  .v-text-field__search {
    height: 30px!important;
    ::v-deep .v-input__control {
      min-height: 30px!important;
    }
  }
</style>
